import {t} from "i18next";

export const levelThemes = {
  // Standard
  'japan': {
    id: 'japan',
    name: 'Japan',
    recommendations: {
      colors: [],
      tilesOrder: []
    },
    availableBackgrounds: [
      {
        id: 'japanese-calm-room',
        path: '/images/level-editor/backgrounds/japan-background-1.webp',
        title: 'Japanese calm room',
      },
    ],
    order: 1
  }
}