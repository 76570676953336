import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authRoutes from '../../Containers/Auth/Routes/AuthRoutes';
import dashboardRoutes from '../../Containers/Dashboard/Routes/DashboardRoutes';
import gameManagementRoutes from '../../Containers/GameManagementSection/Game/Routes/Map';
import playerManagementRoutes from '../../Containers/GameManagementSection/Player/Routes/Map';
import layoutManagementRoutes from '../../Containers/GameManagementSection/Layout/Routes/Map';
import settingManagementRoutes from '../../Containers/GameManagementSection/Setting/Routes/Map';
import levelManagementRoutes from '../../Containers/GameManagementSection/Level/Routes/Map';
import HorizontalLayout from '../Themes/Skote/Layouts/HorizontalLayout';
import Page404 from '../Themes/Skote/Pages/Page404';
import RouterFacade from '../Libs/Router/RouterFacade';
import ApiSenderFacade from '../Libs/ApiSender/ApiSenderFacade';
import userManagementRoutes from '../../Containers/UserManagement/Routes/UserManagementRoutes';

function RootRoutes() {
  ApiSenderFacade.getInstance(useDispatch());

  const router = createBrowserRouter([
    RouterFacade.process(
      { isAuthProtected: false, children: authRoutes },
    ),
    RouterFacade.process(
      { isAuthProtected: true, children: dashboardRoutes, layout: HorizontalLayout },
    ),

    // Game management section
    RouterFacade.process(
      { isAuthProtected: true, children: gameManagementRoutes, layout: HorizontalLayout },
    ),
    RouterFacade.process(
      { isAuthProtected: true, children: playerManagementRoutes, layout: HorizontalLayout },
    ),
    RouterFacade.process(
      { isAuthProtected: true, children: layoutManagementRoutes, layout: HorizontalLayout },
    ),
    RouterFacade.process(
      { isAuthProtected: true, children: settingManagementRoutes, layout: HorizontalLayout },
    ),
    RouterFacade.process(
        { isAuthProtected: true, children: levelManagementRoutes, layout: HorizontalLayout },
    ),


    RouterFacade.process(
      { isAuthProtected: true, children: userManagementRoutes, layout: HorizontalLayout },
    ),
    RouterFacade.process(
      { path: '/*', isAuthProtected: false, element: <Page404 /> },
    ),
  ]);

  return (
    <RouterProvider
      router={router}
      fallbackElement={<div>Loading...</div>}
    />
  );
}

export default RootRoutes;
