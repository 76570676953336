import React, { lazy } from 'react';
import {
  ROUTE_LEVELS_CREATE_PAGE,
  ROUTE_LEVELS_EDIT_PAGE,
} from '../../../../Ship/Constants/urls';

const LevelEditPage = lazy(() => import('../Components/Pages/LevelEditPage/LevelEditPage'));

const LevelRoutes = [
  {
    path: ROUTE_LEVELS_EDIT_PAGE,
    element: <LevelEditPage isEdit={true} />,
  },
  {
    path: ROUTE_LEVELS_CREATE_PAGE,
    element: <LevelEditPage isEdit={false} />,
  }
];

export default LevelRoutes;
