import {call, put, takeEvery} from 'redux-saga/effects';

import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import notify from '../../../../Ship/Helpers/Notification';
import {
  API_CREATE_LEVEL,
  API_FETCH_LEVEL_DETAILS, API_PUBLISH_LEVEL,
  API_UPDATE_LEVEL
} from "../Constants/ApiRoutes";
import {
  setLevelDetailsEvent
} from "./actions";
import {
  ACTION_CREATE_LEVEL,
  ACTION_FETCH_LEVEL_DETAILS, ACTION_PUBLISH_LEVEL,
  ACTION_UPDATE_LEVEL
} from "./actionTypes";
import {elementsDatabase, techMapToElements} from "../Constants/LevelElementsSchema";

function* updateLevelWorker({payload: {levelId, data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (level) => apiHelper
        .put(
          API_UPDATE_LEVEL.replace('{level_id}', level.id),
          level.data,
        ),
      {
        id: levelId,
        data: {
          configs: data,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The level was updated',
        },
      );

      // yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the level.',
    });
  }
}

function* createLevelWorker({payload: {data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (level) => apiHelper
        .post(
          API_CREATE_LEVEL,
          level.data,
        ),
      {
        data: {
          configs: data
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The level was created, the list is being updating',
        },
      );

      // yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the level.',
    });
  }
}

function* publishLevelWorker({payload: {levelId}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (level) => {
        console.log(level);
        
        return apiHelper
          .post(
            API_PUBLISH_LEVEL.replace('{level_id}', level.id),
            {}
          );
      },
      {
        id: levelId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The level was published',
        },
      );

      // yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while publishing the level.',
    });
  }
}

function* fetchLevelDetailsWorker({payload: {levelId}}) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (level) => apiHelper
        .get(
          API_FETCH_LEVEL_DETAILS.replace('{level_id}', level.id),
        ),
      {
        id: levelId,
      },
    );

    const parsedData = JSON.parse(response.configs);

    const getFullFieldName = (key, data) => {
      if (data.hasOwnProperty(key + 'Durability')) {
        return data[key + 'Type'] + '-' + data[key + 'Durability'];
      }

      if (data.hasOwnProperty('durability')) {
        return data[key + 'Type'] + '-' + data['durability'];
      }

      return data[key + 'Type'];
    }

    yield put(setLevelDetailsEvent(
      {
        ...parsedData,
        boardState: parsedData.boardState.reduce(
          (acc, cur) => {
            return {
              ...acc,
              ["x" + cur.xPos + "y" + cur.yPos]: {
                tile: techMapToElements[getFullFieldName('tile', cur)],
                tileContent: techMapToElements[getFullFieldName('tileContent', cur)],
                tileModifiers: cur.tileModifiers.map((modifKey) => techMapToElements[getFullFieldName('tileModifier', modifKey)]),
                tileContentModifiers: cur.tileContentModifiers.map((modifKey) => techMapToElements[getFullFieldName('tileContentModifier', modifKey)]),
                xPos: cur.xPos,
                yPos: cur.yPos,
              }
            }
          }, {}
        ),
        generatableItems: new Set(parsedData.generatableItems),
      }
    ));
  } catch (error) {
    // console.error('An error occurred while fetching game:', error);
  }
}

function* levelsSagaWatcher() {
  // yield takeEvery(ACTION_FETCH_LEVELS, fetchLevelsWorker);
  // yield takeEvery(ACTION_DELETE_LEVEL, deleteLevelWorker);
  yield takeEvery(ACTION_UPDATE_LEVEL, updateLevelWorker);
  yield takeEvery(ACTION_PUBLISH_LEVEL, publishLevelWorker);
  yield takeEvery(ACTION_CREATE_LEVEL, createLevelWorker);
  yield takeEvery(ACTION_FETCH_LEVEL_DETAILS, fetchLevelDetailsWorker);
}

export default levelsSagaWatcher;
