import { combineReducers } from 'redux';

import Auth from '../../Containers/Auth/Store/Login/reducer';
import System from './Reducers/system';

// GameManagementSection
import GameContainer from '../../Containers/GameManagementSection/Game/Store/reducer';
import PlayerContainer from '../../Containers/GameManagementSection/Player/Store/reducer';
import SettingContainer from '../../Containers/GameManagementSection/Setting/Store/reducer';
import LayoutContainer from '../../Containers/GameManagementSection/Layout/Store/reducer';
import LevelContainer from '../../Containers/GameManagementSection/Level/Store/reducer';

import UserManagement from '../../Containers/UserManagement/Store/reducer';

const rootReducer = combineReducers({
  Auth,
  System,
  GameManagement: combineReducers({
    GameContainer,
    PlayerContainer,
    SettingContainer,
    LayoutContainer,
    LevelContainer,
  }),
  UserManagement,
});

export default rootReducer;
