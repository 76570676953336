export const API_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost'
  : 'https://codebase-team.com';

export const APP_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000'
  : 'https://codebase-team.com';

export const ROUTE_GAMES_PAGE = '/games';
export const ROUTE_USERS_PAGE = '/users';
export const ROUTE_PLAYERS_PAGE = '/games/:game/players';

export const ROUTE_GAME_CONCERNS_PAGE = '/games/:game/concerns';

export const ROUTE_SETTINGS_CREATE_PAGE = '/games/:game/settings/create';
export const ROUTE_SETTINGS_WITH_LAYOUT_CREATE_PAGE = '/games/:game/layouts/:layout/settings/safe-create';

export const ROUTE_SETTINGS_EDIT_PAGE = '/games/:game/settings/:setting/edit';
export const ROUTE_SETTINGS_WITH_LAYOUT_EDIT_PAGE = '/games/:game/layouts/:layout/settings/:setting/safe-edit';

export const ROUTE_LAYOUTS_CREATE_PAGE = '/games/:game/layouts/create';
export const ROUTE_LAYOUTS_EDIT_PAGE = '/games/:game/layouts/:layout/edit';
export const ROUTE_LEVELS_EDIT_PAGE = '/levels/:level/edit/';
export const ROUTE_LEVELS_CREATE_PAGE = '/levels/create/';
