import {t} from "i18next";
import ELEMENT_TYPES from "./ElementTypes";

import coloredBlueSprite from '../Assets/ElementSprites/colored-blue.png';
import coloredRedSprite from '../Assets/ElementSprites/colored-red.png';
import coloredGreenSprite from '../Assets/ElementSprites/colored-green.png';
import coloredYellowSprite from '../Assets/ElementSprites/colored-yellow.png';
import coloredPurpleSprite from '../Assets/ElementSprites/colored-purple.png';
import contentModifierIce1Sprite from '../Assets/ElementSprites/content-modifier-ice-1.png';
import contentModifierIce2Sprite from '../Assets/ElementSprites/content-modifier-ice-2.png';
import contentModifierIce3Sprite from '../Assets/ElementSprites/content-modifier-ice-3.png';
import contentModifierLocker1Sprite from '../Assets/ElementSprites/content-modifier-locker-1.png';
import contentModifierLocker2Sprite from '../Assets/ElementSprites/content-modifier-locker-2.png';
import contentModifierLocker3Sprite from '../Assets/ElementSprites/content-modifier-locker-3.png';
import contentWall1Sprite from '../Assets/ElementSprites/content-wall-1.png';
import contentWall2Sprite from '../Assets/ElementSprites/content-wall-2.png';
import contentWall3Sprite from '../Assets/ElementSprites/content-wall-3.png';
import contentPowerUpBombSprite from '../Assets/ElementSprites/power-up-bomb.png';
import contentPowerUpRocketVerticalSprite from '../Assets/ElementSprites/power-up-rocket-vertical.png';
import contentPowerUpRocketHorizontalSprite from '../Assets/ElementSprites/power-up-rocket-horizontal.png';
import contentPowerUpMagicBallSprite from '../Assets/ElementSprites/power-up-magic-ball.png';
import tile1Sprite from '../Assets/ElementSprites/tile-1.png';
import tile2Sprite from '../Assets/ElementSprites/tile-2.png';
import tileModifierGrass1Sprite from '../Assets/ElementSprites/tile-modifier-grass-1.png';
import tileModifierGrass2Sprite from '../Assets/ElementSprites/tile-modifier-grass-2.png';
import tileModifierGrass3Sprite from '../Assets/ElementSprites/tile-modifier-grass-3.png';
import tileModifierSpawnerSprite from '../Assets/ElementSprites/tile-modifier-spawner.png';

export const elementsDatabase = {
  // Tiles
  'tile-1': {
    id: 'tile-1',
    title: t('Tile light'),
    sprite: tile1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE,
    techProps: {
      tileType: 'common-1'
    }
  },
  'tile-2': {
    id: 'tile-2',
    title: t('Tile dark'),
    sprite: tile2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE,
    techProps: {
      tileType: 'common-2'
    }
  },

  // Balls
  'colorGreen': {
    id: 'colorGreen',
    title: t('Green ball'),
    sprite: coloredGreenSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'colored-green'
    }
  },
  'colorRed': {
    id: 'colorRed',
    title: t('Red ball'),
    sprite: coloredRedSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'colored-red'
    }
  },
  'colorYellow': {
    id: 'colorYellow',
    title: t('Yellow ball'),
    sprite: coloredYellowSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'colored-yellow'
    }
  },
  'colorBlue': {
    id: 'colorBlue',
    title: t('Blue ball'),
    sprite: coloredBlueSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'colored-blue'
    }
  },
  'colorPurple': {
    id: 'colorPurple',
    title: t('Purple ball'),
    sprite: coloredPurpleSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'colored-purple'
    }
  },
  'wall-1': {
    id: 'wall-1',
    title: t('Wall weak'),
    sprite: contentWall1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 1
    }
  },
  'wall-2': {
    id: 'wall-2',
    title: t('Wall normal'),
    sprite: contentWall2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 2
    }
  },
  'wall-3': {
    id: 'wall-3',
    title: t('Wall strong'),
    sprite: contentWall3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 3
    }
  },
  'powerUpBomb': {
    id: 'powerUpBomb',
    title: t('Bomb'),
    sprite: contentPowerUpBombSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'power-up-bomb'
    }
  },
  'powerUpRocketVertical': {
    id: 'powerUpRocketVertical',
    title: t('Rocket'),
    sprite: contentPowerUpRocketVerticalSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'power-up-vertical-rocket'
    }
  },
  'powerUpRocketHorizontal': {
    id: 'powerUpRocketHorizontal',
    title: t('Rocket'),
    sprite: contentPowerUpRocketHorizontalSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'power-up-horizontal-rocket'
    }
  },
  'powerUpMagicBall': {
    id: 'powerUpMagicBall',
    title: t('Magic ball'),
    sprite: contentPowerUpMagicBallSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'power-up-magic-ball'
    }
  },

  // Content Modifiers
  'ice-1': {
    id: 'ice-1',
    sprite: contentModifierIce1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-ice',
      durability: 1
    }
  },
  'ice-2': {
    id: 'ice-2',
    sprite: contentModifierIce2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-ice',
      durability: 2
    }
  },
  'ice-3': {
    id: 'ice-3',
    sprite: contentModifierIce3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-ice',
      durability: 3
    }
  },
  'locker-1': {
    id: 'locker-1',
    sprite: contentModifierLocker1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-locker',
      durability: 1
    }
  },
  'locker-2': {
    id: 'locker-2',
    sprite: contentModifierLocker2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-locker',
      durability: 2
    }
  },
  'locker-3': {
    id: 'locker-3',
    sprite: contentModifierLocker3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'content-modifier-locker',
      durability: 3
    }
  },

  // Tile Modifiers
  'spawner': {
    id: 'spawner',
    sprite: tileModifierSpawnerSprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tile-modifier-spawner',
    }
  },

  'grass-1': {
    id: 'grass-1',
    sprite: tileModifierGrass1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tile-modifier-grass',
      durability: 1,
    }
  },

  'grass-2': {
    id: 'grass-2',
    sprite: tileModifierGrass2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tile-modifier-grass',
      durability: 2,
    }
  },

  'grass-3': {
    id: 'grass-3',
    sprite: tileModifierGrass3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tile-modifier-grass',
      durability: 3,
    }
  }
}

export const techMapToElements = {
  'common-1': elementsDatabase['tile-1'],
  'common-2': elementsDatabase['tile-2'],
  'colored-green': elementsDatabase['colorGreen'],
  'colored-red': elementsDatabase['colorRed'],
  'colored-yellow': elementsDatabase['colorYellow'],
  'colored-blue': elementsDatabase['colorBlue'],
  'colored-purple': elementsDatabase['colorPurple'],
  'wall-1': elementsDatabase['wall-1'],
  'wall-2': elementsDatabase['wall-2'],
  'wall-3': elementsDatabase['wall-3'],
  'power-up-bomb': elementsDatabase['powerUpBomb'],
  'power-up-vertical-rocket': elementsDatabase['powerUpRocketVertical'],
  'power-up-horizontal-rocket': elementsDatabase['powerUpRocketHorizontal'],
  'power-up-magic-ball': elementsDatabase['powerUpMagicBall'],
  'content-modifier-ice-1': elementsDatabase['ice-1'],
  'content-modifier-ice-2': elementsDatabase['ice-2'],
  'content-modifier-ice-3': elementsDatabase['ice-3'],
  'content-modifier-locker-1': elementsDatabase['locker-1'],
  'content-modifier-locker-2': elementsDatabase['locker-2'],
  'content-modifier-locker-3': elementsDatabase['locker-3'],
  'tile-modifier-spawner': elementsDatabase['spawner'],
  'tile-modifier-grass-1': elementsDatabase['grass-1'],
  'tile-modifier-grass-2': elementsDatabase['grass-2'],
  'tile-modifier-grass-3': elementsDatabase['grass-3'],
}

export const generatableElements = [
  elementsDatabase["colorGreen"],
  elementsDatabase["colorRed"],
  elementsDatabase["colorBlue"],
  elementsDatabase["colorYellow"],
  elementsDatabase["colorPurple"],
]

export const goalableLevelElements = [
  elementsDatabase["colorGreen"],
  elementsDatabase["colorRed"],
  elementsDatabase["colorBlue"],
  elementsDatabase["colorYellow"],
  elementsDatabase["colorPurple"],
  elementsDatabase["wall-3"],
  elementsDatabase["powerUpBomb"],
  elementsDatabase["powerUpRocketHorizontal"],
  elementsDatabase["powerUpRocketVertical"],
  elementsDatabase["powerUpMagicBall"],
];

export const elementsTypesTabs = [
  {
    title: t('Tiles'),
    iconClass: 'bx bx-square-rounded bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE,
    elements: [
      elementsDatabase["tile-1"],
      elementsDatabase["tile-2"],
    ]
  },
  {
    title: t('Tile modifiers'),
    iconClass: 'bx bx-shape-square bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    elements: [
      elementsDatabase["grass-1"],
      elementsDatabase["grass-2"],
      elementsDatabase["grass-3"],
      elementsDatabase["spawner"],
    ]
  },
  {
    title: t('Contents'),
    iconClass: 'bx bx-circle bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    elements: [
      elementsDatabase["colorGreen"],
      elementsDatabase["colorRed"],
      elementsDatabase["colorBlue"],
      elementsDatabase["colorYellow"],
      elementsDatabase["colorPurple"],
      elementsDatabase["wall-1"],
      elementsDatabase["wall-2"],
      elementsDatabase["wall-3"],
      elementsDatabase["powerUpBomb"],
      elementsDatabase["powerUpRocketHorizontal"],
      elementsDatabase["powerUpRocketVertical"],
      elementsDatabase["powerUpMagicBall"],
    ]
  },
  {
    title: t('Content modifiers'),
    iconClass: 'bx bx-loader-circle bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    elements: [
      elementsDatabase["ice-1"],
      elementsDatabase["ice-2"],
      elementsDatabase["ice-3"],
      elementsDatabase["locker-1"],
      elementsDatabase["locker-2"],
      elementsDatabase["locker-3"],
    ]
  },
]