import {
  ACTION_SET_LEVEL_DETAILS, 
  ACTION_SET_LEVEL_MODULE_STATES,
  ACTION_SET_LEVELS,
} from './actionTypes';
import {levelThemes} from "../Constants/LevelThemes";
import {levelTypes} from "../Constants/LevelTypes";

const INIT_STATE = {
  levels: [],
  levelDetails: {
    boardState: {},
    generatableItems: new Set(),
    theme: levelThemes.japan.id,
    levelBackground: levelThemes.japan.availableBackgrounds[0].id,
    levelType: levelTypes.simple,
    goals: {},
    scoringSchema: {
      star1: 50,
      star2: 150,
      star3: 300
    },
  },
  moduleStates: {
    selectedElement: null,
  },
};

const LevelManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_LEVELS:
      return {
        ...state,
        levels: action.payload.levels,
      };

    case ACTION_SET_LEVEL_DETAILS:
      return {
        ...state,
        levelDetails: {
          ...state.levelDetails,
          ...action.payload.levelData
        },
      };

    case ACTION_SET_LEVEL_MODULE_STATES:
      return {
        ...state,
        moduleStates: {
          ...action.payload.levelStates
        },
      };

    default:
      return state;
  }
};

export default LevelManagement;
